define("discourse/plugins/discourse-calendar/discourse/connectors/after-user-name/holiday-flair", ["exports", "discourse/lib/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, context) {
      return context.siteSettings.calendar_enabled && context.site.users_on_holiday && context.site.users_on_holiday.includes(args.user.username);
    },
    setupComponent(args, component) {
      const holidayEmojiName = this.get("siteSettings.holiday_status_emoji") || "date";
      component.setProperties({
        holidayEmojiName: `:${holidayEmojiName}:`,
        holidayEmoji: (0, _text.emojiUrlFor)(holidayEmojiName)
      });
    }
  };
});