define("discourse/plugins/discourse-calendar/discourse/initializers/add-event-ui-builder", ["exports", "@ember/object", "discourse/lib/plugin-api", "discourse/plugins/discourse-calendar/discourse/components/modal/post-event-builder", "discourse/plugins/discourse-calendar/discourse/widgets/discourse-post-event"], function (_exports, _object, _pluginApi, _postEventBuilder, _discoursePostEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeEventBuilder(api) {
    const currentUser = api.getCurrentUser();
    const store = api.container.lookup("service:store");
    const modal = api.container.lookup("service:modal");
    api.addComposerToolbarPopupMenuOption({
      action: toolbarEvent => {
        const eventModel = store.createRecord("discourse-post-event-event");
        eventModel.setProperties({
          status: "public",
          custom_fields: _object.default.create({}),
          starts_at: moment(),
          timezone: moment.tz.guess()
        });
        modal.show(_postEventBuilder.default, {
          model: {
            event: eventModel,
            toolbarEvent,
            updateCustomField: (field, value) => (0, _discoursePostEvent.updateCustomField)(eventModel, field, value),
            updateEventStatus: status => (0, _discoursePostEvent.updateEventStatus)(eventModel, status),
            updateEventRawInvitees: rawInvitees => (0, _discoursePostEvent.updateEventRawInvitees)(eventModel, rawInvitees),
            removeReminder: reminder => (0, _discoursePostEvent.removeReminder)(eventModel, reminder),
            addReminder: () => (0, _discoursePostEvent.addReminder)(eventModel),
            onChangeDates: changes => (0, _discoursePostEvent.onChangeDates)(eventModel, changes),
            updateTimezone: (newTz, startsAt, endsAt) => (0, _discoursePostEvent.updateTimezone)(eventModel, newTz, startsAt, endsAt)
          }
        });
      },
      group: "insertions",
      icon: "calendar-day",
      label: "discourse_calendar.discourse_post_event.builder_modal.attach",
      condition: composer => {
        if (!currentUser || !currentUser.can_create_discourse_post_event) {
          return false;
        }
        const composerModel = composer.model;
        return composerModel && !composerModel.replyingToTopic && (composerModel.topicFirstPost || composerModel.creatingPrivateMessage || composerModel.editingPost && composerModel.post && composerModel.post.post_number === 1);
      }
    });
  }
  var _default = _exports.default = {
    name: "add-post-event-builder",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.discourse_post_event_enabled) {
        (0, _pluginApi.withPluginApi)("0.8.7", initializeEventBuilder);
      }
    }
  };
});