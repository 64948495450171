define("discourse/plugins/discourse-calendar/discourse/widgets/discourse-post-event-host", ["exports", "virtual-dom", "discourse/lib/utilities", "discourse/widgets/post", "discourse/widgets/widget"], function (_exports, _virtualDom, _utilities, _post, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-post-event-creator", {
    tagName: "span.event-creator",
    html(attrs) {
      const {
        name,
        username,
        avatar_template
      } = attrs.user;
      return (0, _virtualDom.h)("a", {
        attributes: {
          class: "topic-invitee-avatar",
          "data-user-card": username
        }
      }, [(0, _post.avatarImg)("tiny", {
        template: avatar_template,
        username: name || (0, _utilities.formatUsername)(username)
      }), (0, _virtualDom.h)("span", {
        attributes: {
          class: "username"
        }
      }, name || (0, _utilities.formatUsername)(username))]);
    }
  });
});