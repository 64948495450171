define("discourse/plugins/discourse-calendar/discourse/widgets/discourse-group-timezones-time-traveler", ["exports", "discourse/widgets/widget", "discourse/plugins/discourse-calendar/discourse/lib/round-time"], function (_exports, _widget, _roundTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-group-timezones-time-traveler", {
    tagName: "div.group-timezones-time-traveler",
    transform(attrs) {
      let date = moment().add(attrs.localTimeOffset, "minutes");
      if (attrs.localTimeOffset) {
        date = (0, _roundTime.default)(date);
      }
      return {
        localTimeWithOffset: date.format("HH:mm")
      };
    },
    template: function (attrs, state) {
      var _r = [];
      _r.push("\n    ");
      var _a0 = [];
      _a0.push("\n      ");
      _a0.push(this.transformed.localTimeWithOffset);
      _a0.push("\n    ");
      _r.push(virtualDom.h('span', {
        "className": "time",
        "attributes": {}
      }, _a0));
      _r.push("\n    ");
      var _a1 = [];
      _a1.push("\n      ");
      _a1.push(this.attach("discourse-group-timezones-slider", undefined, undefined, undefined));
      _a1.push("\n    ");
      _r.push(virtualDom.h('span', {
        "className": "discourse-group-timezones-slider-wrapper",
        "attributes": {}
      }, _a1));
      _r.push("\n    ");
      _r.push(this.attach("discourse-group-timezones-reset", {
        "id": attrs.id,
        "localTimeOffset": attrs.localTimeOffset
      }, undefined, undefined));
      _r.push("\n  ");
      return _r;
    }
  });
});