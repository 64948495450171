define("discourse/plugins/discourse-calendar/discourse/adapters/discourse-post-event-nested-adapter", ["exports", "@ember/string", "discourse/adapters/rest", "discourse/lib/ajax", "discourse/plugins/discourse-calendar/discourse/adapters/discourse-post-event-adapter"], function (_exports, _string, _rest, _ajax, _discoursePostEventAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discoursePostEventAdapter.default.extend({
    // TODO: destroy/update/create should be improved in core to allow for nested models
    destroyRecord(store, type, record) {
      return (0, _ajax.ajax)(this.pathFor(store, type, {
        post_id: record.post_id,
        id: record.id
      }), {
        type: "DELETE"
      });
    },
    update(store, type, id, attrs) {
      const data = {};
      const typeField = (0, _string.underscore)(this.apiNameFor(type));
      data[typeField] = attrs;
      return (0, _ajax.ajax)(this.pathFor(store, type, {
        id,
        post_id: attrs.post_id
      }), this.getPayload("PUT", data)).then(function (json) {
        return new _rest.Result(json[typeField], json);
      });
    },
    createRecord(store, type, attrs) {
      const data = {};
      const typeField = (0, _string.underscore)(this.apiNameFor(type));
      data[typeField] = attrs;
      return (0, _ajax.ajax)(this.pathFor(store, type, attrs), this.getPayload("POST", data)).then(function (json) {
        return new _rest.Result(json[typeField], json);
      });
    },
    pathFor(store, type, findArgs) {
      const post_id = findArgs["post_id"];
      delete findArgs["post_id"];
      const id = findArgs["id"];
      delete findArgs["id"];
      let path = this.basePath(store, type, {}) + "events/" + post_id + "/" + (0, _string.underscore)(store.pluralize(this.apiNameFor()));
      if (id) {
        path += `/${id}`;
      }
      return this.appendQueryParams(path, findArgs);
    }
  });
});