define("discourse/plugins/discourse-calendar/discourse/helpers/format-future-date", ["exports", "discourse-common/lib/helpers", "discourse/plugins/discourse-calendar/discourse/lib/guess-best-date-format"], function (_exports, _helpers, _guessBestDateFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helpers.htmlHelper)(date => {
    date = moment.utc(date).tz(moment.tz.guess());
    const format = (0, _guessBestDateFormat.default)(date);
    return date.format(format);
  });
});