define("discourse/plugins/discourse-calendar/discourse/widgets/discourse-group-timezones-reset", ["exports", "discourse/widgets/widget"], function (_exports, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-group-timezones-reset", {
    tagName: "div.group-timezones-reset",
    onResetOffset() {
      this.sendWidgetAction("onChangeCurrentUserTimeOffset", 0);
      const container = document.getElementById(this.attrs.id);
      const slider = container.querySelector("input[type=range].group-timezones-slider");
      if (slider) {
        slider.value = 0;
      }
    },
    transform(attrs) {
      return {
        isDisabled: attrs.localTimeOffset === 0
      };
    },
    template: function (attrs, state) {
      var _r = [];
      _r.push("\n    ");
      _r.push(this.attach("button", {
        "disabled": this.transformed.isDisabled,
        "action": "onResetOffset",
        "icon": "undo"
      }, undefined, undefined));
      _r.push("\n  ");
      return _r;
    }
  });
});