define("discourse/plugins/discourse-calendar/discourse/models/discourse-post-event-event", ["exports", "discourse/lib/ajax", "discourse/models/rest"], function (_exports, _ajax, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ATTRIBUTES = {
    id: null,
    name: null,
    starts_at: null,
    ends_at: null,
    raw_invitees: null,
    url: null,
    timezone: null,
    status: {
      transform(value) {
        return STATUSES[value];
      }
    }
  };
  const STATUSES = {
    standalone: 0,
    public: 1,
    private: 2
  };
  const Event = _rest.default.extend({
    init() {
      this._super(...arguments);
      this.__type = "discourse-post-event-event";
    },
    update(data) {
      return (0, _ajax.ajax)(`/discourse-post-event/events/${this.id}.json`, {
        type: "PUT",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          event: data
        })
      });
    },
    updateProperties() {
      const attributesKeys = Object.keys(ATTRIBUTES);
      return this.getProperties(attributesKeys);
    },
    createProperties() {
      const attributesKeys = Object.keys(ATTRIBUTES);
      return this.getProperties(attributesKeys);
    },
    _transformProps(props) {
      const attributesKeys = Object.keys(ATTRIBUTES);
      attributesKeys.forEach(key => {
        const attribute = ATTRIBUTES[key];
        if (attribute?.transform) {
          props[key] = attribute.transform(props[key]);
        }
      });
    },
    beforeUpdate(props) {
      this._transformProps(props);
    },
    beforeCreate(props) {
      this._transformProps(props);
    }
  });
  var _default = _exports.default = Event;
});