define("discourse/plugins/discourse-calendar/discourse/lib/calendar-locale", ["exports", "I18n"], function (_exports, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getCalendarButtonsText = getCalendarButtonsText;
  _exports.getCurrentBcp47Locale = getCurrentBcp47Locale;
  function getCurrentBcp47Locale() {
    return _I18n.default.currentLocale().replace("_", "-");
  }
  function getCalendarButtonsText() {
    return {
      today: _I18n.default.t("discourse_calendar.toolbar_button.today"),
      month: _I18n.default.t("discourse_calendar.toolbar_button.month"),
      week: _I18n.default.t("discourse_calendar.toolbar_button.week"),
      day: _I18n.default.t("discourse_calendar.toolbar_button.day"),
      list: _I18n.default.t("discourse_calendar.toolbar_button.list")
    };
  }
});