define("discourse/plugins/discourse-calendar/discourse/lib/popover", ["exports", "@popperjs/core"], function (_exports, _core) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildPopover = buildPopover;
  _exports.destroyPopover = destroyPopover;
  let eventPopper;
  const EVENT_POPOVER_ID = "event-popover";
  function buildPopover(jsEvent, htmlContent) {
    const node = document.createElement("div");
    node.setAttribute("id", EVENT_POPOVER_ID);
    node.innerHTML = htmlContent;
    const arrow = document.createElement("span");
    arrow.dataset.popperArrow = true;
    node.appendChild(arrow);
    document.body.appendChild(node);
    eventPopper = (0, _core.createPopper)(jsEvent.target, document.getElementById(EVENT_POPOVER_ID), {
      placement: "bottom",
      modifiers: [{
        name: "arrow"
      }, {
        name: "offset",
        options: {
          offset: [20, 10]
        }
      }]
    });
  }
  function destroyPopover() {
    eventPopper?.destroy();
    document.getElementById(EVENT_POPOVER_ID)?.remove();
  }
});