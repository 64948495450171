define("discourse/plugins/discourse-calendar/discourse/routes/discourse-post-event-upcoming-events-index", ["exports", "@ember/object/evented", "@ember/routing/route", "discourse/lib/url"], function (_exports, _evented, _route, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    enforcePostEventEnabled: (0, _evented.on)("activate", function () {
      if (!this.siteSettings.discourse_post_event_enabled) {
        _url.default.redirectTo("/404");
      }
    }),
    model(params) {
      if (this.siteSettings.include_expired_events_on_calendar) {
        params.include_expired = true;
      }
      return this.store.findAll("discourse-post-event-event", params);
    }
  });
});