define("discourse/plugins/discourse-calendar/discourse/helpers/render-invitee", ["exports", "@ember/template", "discourse/helpers/user-avatar", "discourse/lib/url", "discourse/lib/utilities", "discourse-common/lib/helpers"], function (_exports, _template, _userAvatar, _url, _utilities, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helpers.htmlHelper)(invitee => {
    const path = (0, _url.userPath)(invitee.user.username);
    const template = `
    <a href="${path}" data-user-card="${invitee.user.username}">
      <span class="user">
        ${(0, _userAvatar.renderAvatar)(invitee.user, {
      imageSize: "medium"
    })}
        <span class="username">
         ${(0, _utilities.formatUsername)(invitee.user.username)}
        </span>
      </span>
    </a>
  `;
    return (0, _template.htmlSafe)(template);
  });
});