define("discourse/plugins/discourse-calendar/discourse/widgets/discourse-group-timezone-new-day", ["exports", "discourse/widgets/widget"], function (_exports, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-group-timezone-new-day", {
    tagName: "div.group-timezone-new-day",
    template: function (attrs, state) {
      var __h1 = __widget_helpers.iconNode;
      var _r = [];
      _r.push("\n    ");
      var _a0 = [];
      _a0.push("\n      ");
      _a0.push(__h1("chevron-left"));
      _a0.push("\n      ");
      _a0.push(this.attrs.groupedTimezone.beforeDate);
      _a0.push("\n    ");
      _r.push(virtualDom.h('span', {
        "className": "before",
        "attributes": {}
      }, _a0));
      _r.push("\n    ");
      var _a1 = [];
      _a1.push("\n      ");
      _a1.push(this.attrs.groupedTimezone.afterDate);
      _a1.push("\n      ");
      _a1.push(__h1("chevron-right"));
      _a1.push("\n    ");
      _r.push(virtualDom.h('span', {
        "className": "after",
        "attributes": {}
      }, _a1));
      _r.push("\n  ");
      return _r;
    }
  });
});