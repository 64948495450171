define("discourse/plugins/discourse-calendar/discourse/components/admin-holidays-list", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <table class="holidays-list">
    <thead>
      <tr>
        <td>{{i18n "discourse_calendar.date"}}</td>
        <td colspan="2">{{i18n "discourse_calendar.holiday"}}</td>
      </tr>
    </thead>
  
    <tbody>
      {{#each @holidays as |holiday|}}
        <AdminHolidaysListItem
          @holiday={{holiday}}
          @isHolidayDisabled={{holiday.disabled}}
          @region_code={{@region_code}}
        />
      {{/each}}
    </tbody>
  </table>
  */
  {
    "id": "4wGqTLXi",
    "block": "[[[10,\"table\"],[14,0,\"holidays-list\"],[12],[1,\"\\n  \"],[10,\"thead\"],[12],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"td\"],[12],[1,[28,[35,0],[\"discourse_calendar.date\"],null]],[13],[1,\"\\n      \"],[10,\"td\"],[14,\"colspan\",\"2\"],[12],[1,[28,[35,0],[\"discourse_calendar.holiday\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@holiday\",\"@isHolidayDisabled\",\"@region_code\"],[[30,2],[30,2,[\"disabled\"]],[30,3]]],null],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@holidays\",\"holiday\",\"@region_code\"],false,[\"i18n\",\"each\",\"-track-array\",\"admin-holidays-list-item\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/components/admin-holidays-list.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});