define("discourse/plugins/discourse-calendar/discourse/connectors/user-custom-preferences/region", ["exports", "@ember/object", "discourse/plugins/discourse-calendar/discourse/lib/regions"], function (_exports, _object, _regions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      component.setProperties(dt7948.p({
        onChange(value) {
          this.model.set("custom_fields.holidays-region", value);
        },
        useCurrentRegion() {
          this.model.set("custom_fields.holidays-region", _regions.TIME_ZONE_TO_REGION[moment.tz.guess()] || "us");
        }
      }, [["method", "onChange", [_object.action]], ["method", "useCurrentRegion", [_object.action]]]));
    },
    shouldRender(args, component) {
      return component.siteSettings.calendar_enabled;
    }
  };
});