define("discourse/plugins/discourse-calendar/discourse/helpers/format-event-name", ["exports", "discourse-common/lib/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatEventName = formatEventName;
  function formatEventName(event) {
    return event.name || event.post.topic.title;
  }
  var _default = _exports.default = (0, _helpers.htmlHelper)(event => formatEventName(event));
});