define("discourse/plugins/discourse-calendar/discourse/widgets/discourse-post-event-invitees", ["exports", "discourse/widgets/widget"], function (_exports, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-post-event-invitees", {
    tagName: "section.event-invitees",
    transform(attrs) {
      return {
        isPrivateEvent: attrs.eventModel.status === "private"
      };
    },
    template: function (attrs, state) {
      var _r = [];
      _r.push("\n    ");
      var _a0 = [];
      _a0.push("\n      ");
      var _a1 = [];
      _a1.push("\n        ");
      var _a2 = [];
      _a2.push(attrs.eventModel.stats.going);
      _a2.push(" ");
      _a2.push(I18n.t("discourse_calendar.discourse_post_event.models.invitee.status.going"));
      _a2.push(" -");
      _a1.push(virtualDom.h('span', _a2));
      _a1.push("\n        ");
      var _a3 = [];
      _a3.push(attrs.eventModel.stats.interested);
      _a3.push(" ");
      _a3.push(I18n.t("discourse_calendar.discourse_post_event.models.invitee.status.interested"));
      _a3.push(" -");
      _a1.push(virtualDom.h('span', _a3));
      _a1.push("\n        ");
      var _a4 = [];
      _a4.push(attrs.eventModel.stats.not_going);
      _a4.push(" ");
      _a4.push(I18n.t("discourse_calendar.discourse_post_event.models.invitee.status.not_going"));
      _a1.push(virtualDom.h('span', _a4));
      _a1.push("\n");
      if (this.transformed.isPrivateEvent) {
        _a1.push("          ");
        var _a5 = [];
        _a5.push("- on ");
        _a5.push(attrs.eventModel.stats.invited);
        _a5.push(" users invited");
        _a1.push(virtualDom.h('span', {
          "className": "invited",
          "attributes": {}
        }, _a5));
        _a1.push("\n");
      }
      _a1.push("      ");
      _a0.push(virtualDom.h('div', {
        "className": "event-invitees-status",
        "attributes": {}
      }, _a1));
      _a0.push("\n\n      ");
      _a0.push(this.attach("button", {
        "className": "show-all btn-small",
        "label": "discourse_calendar.discourse_post_event.event_ui.show_all",
        "action": "showAllInvitees",
        "actionParam": {
          "postId": attrs.eventModel.id
        }
      }, undefined, undefined));
      _a0.push("\n    ");
      _r.push(virtualDom.h('div', {
        "className": "header",
        "attributes": {}
      }, _a0));
      _r.push("\n    ");
      var _a6 = [];
      _a6.push("\n");
      if (attrs.eventModel.sample_invitees && attrs.eventModel.sample_invitees.length) {
        attrs.eventModel.sample_invitees.forEach(invitee => {
          _a6.push("        ");
          _a6.push(this.attach("discourse-post-event-invitee", {
            "invitee": invitee
          }, undefined, undefined));
          _a6.push("\n");
        });
      }
      _a6.push("    ");
      _r.push(virtualDom.h('ul', {
        "className": "event-invitees-avatars",
        "attributes": {}
      }, _a6));
      _r.push("\n  ");
      return _r;
    }
  });
});