define("discourse/plugins/discourse-calendar/discourse/controllers/admin-plugins-calendar", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _controller, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    selectedRegion: null,
    loading: false,
    async getHolidays(region_code) {
      if (this.loading) {
        return;
      }
      this.set("selectedRegion", region_code);
      this.set("loading", true);
      return (0, _ajax.ajax)(`/admin/discourse-calendar/holiday-regions/${region_code}/holidays`).then(response => {
        this.model.set("holidays", response.holidays);
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set("loading", false));
    }
  }, [["method", "getHolidays", [_object.action]]]));
});