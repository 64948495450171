define("discourse/plugins/discourse-calendar/discourse/components/modal/post-event-invitees", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse-common/utils/decorators", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _decorators, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{this.title}}
    @closeModal={{@closeModal}}
    class={{concat-class
      (or @model.extraClass "invited")
      "post-event-invitees-modal"
    }}
  >
    <:body>
      <Input
        @value={{this.filter}}
        {{on "input" this.onFilterChanged}}
        class="filter"
        placeholder={{i18n
          "discourse_calendar.discourse_post_event.invitees_modal.filter_placeholder"
        }}
      />
      <ToggleInvitees @viewType={{this.type}} @toggle={{this.toggleType}} />
      <ConditionalLoadingSpinner @condition={{this.isLoading}}>
        {{#if this.invitees}}
          <ul class="invitees">
            {{#each this.invitees as |invitee|}}
              <li class="invitee">
                {{render-invitee invitee}}
                {{#if @model.event.can_act_on_discourse_post_event}}
                  <DButton
                    @icon="trash-alt"
                    @action={{fn this.removeInvitee invitee}}
                  />
                {{/if}}
              </li>
            {{/each}}
          </ul>
        {{else}}
          <p class="no-users">
            {{i18n
              "discourse_calendar.discourse_post_event.models.invitee.no_users"
            }}
          </p>
        {{/if}}
      </ConditionalLoadingSpinner>
    </:body>
  </DModal>
  */
  {
    "id": "jVk6Dk0N",
    "block": "[[[8,[39,0],[[16,0,[28,[37,1],[[28,[37,2],[[30,1,[\"extraClass\"]],\"invited\"],null],\"post-event-invitees-modal\"],null]]],[[\"@title\",\"@closeModal\"],[[30,0,[\"title\"]],[30,2]]],[[\"body\"],[[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"filter\"],[16,\"placeholder\",[28,[37,4],[\"discourse_calendar.discourse_post_event.invitees_modal.filter_placeholder\"],null]],[4,[38,5],[\"input\",[30,0,[\"onFilterChanged\"]]],null]],[[\"@value\"],[[30,0,[\"filter\"]]]],null],[1,\"\\n    \"],[8,[39,6],null,[[\"@viewType\",\"@toggle\"],[[30,0,[\"type\"]],[30,0,[\"toggleType\"]]]],null],[1,\"\\n    \"],[8,[39,7],null,[[\"@condition\"],[[30,0,[\"isLoading\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"invitees\"]],[[[1,\"        \"],[10,\"ul\"],[14,0,\"invitees\"],[12],[1,\"\\n\"],[42,[28,[37,10],[[28,[37,10],[[30,0,[\"invitees\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[14,0,\"invitee\"],[12],[1,\"\\n              \"],[1,[28,[35,11],[[30,3]],null]],[1,\"\\n\"],[41,[30,1,[\"event\",\"can_act_on_discourse_post_event\"]],[[[1,\"                \"],[8,[39,12],null,[[\"@icon\",\"@action\"],[\"trash-alt\",[28,[37,13],[[30,0,[\"removeInvitee\"]],[30,3]],null]]],null],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[14,0,\"no-users\"],[12],[1,\"\\n          \"],[1,[28,[35,4],[\"discourse_calendar.discourse_post_event.models.invitee.no_users\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[]]]]]],[\"@model\",\"@closeModal\",\"invitee\"],false,[\"d-modal\",\"concat-class\",\"or\",\"input\",\"i18n\",\"on\",\"toggle-invitees\",\"conditional-loading-spinner\",\"if\",\"each\",\"-track-array\",\"render-invitee\",\"d-button\",\"fn\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/components/modal/post-event-invitees.hbs",
    "isStrictMode": false
  });
  class PostEventInvitees extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.inject]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "invitees", [_tracking.tracked]))();
    #invitees = (() => (dt7948.i(this, "invitees"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "filter", [_tracking.tracked]))();
    #filter = (() => (dt7948.i(this, "filter"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "type", [_tracking.tracked], function () {
      return "going";
    }))();
    #type = (() => (dt7948.i(this, "type"), void 0))();
    constructor() {
      super(...arguments);
      this._fetchInvitees();
    }
    get title() {
      return _discourseI18n.default.t(`discourse_calendar.discourse_post_event.invitees_modal.${this.args.model.title || "title_invited"}`);
    }
    toggleType(type) {
      this.type = type;
      this._fetchInvitees(this.filter);
    }
    static #_6 = (() => dt7948.n(this.prototype, "toggleType", [_object.action]))();
    onFilterChanged() {
      this._fetchInvitees(this.filter);
    }
    static #_7 = (() => dt7948.n(this.prototype, "onFilterChanged", [(0, _decorators.debounce)(250)]))();
    async removeInvitee(invitee) {
      await invitee.destroyRecord();
      this._fetchInvitees();
    }
    static #_8 = (() => dt7948.n(this.prototype, "removeInvitee", [_object.action]))();
    async _fetchInvitees(filter) {
      try {
        this.isLoading = true;
        const invitees = await this.store.findAll("discourse-post-event-invitee", {
          filter,
          post_id: this.args.model.event.id,
          type: this.type
        });
        this.invitees = invitees;
      } finally {
        this.isLoading = false;
      }
    }
  }
  _exports.default = PostEventInvitees;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostEventInvitees);
});