define("discourse/plugins/discourse-calendar/discourse/widgets/discourse-group-timezones-member", ["exports", "virtual-dom", "discourse/lib/utilities", "discourse/widgets/post", "discourse/widgets/widget"], function (_exports, _virtualDom, _utilities, _post, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-group-timezones-member", {
    tagName: "li.group-timezones-member",
    buildClasses(attrs) {
      return attrs.member.on_holiday ? "on-holiday" : "not-on-holiday";
    },
    html(attrs) {
      const {
        name,
        username,
        avatar_template
      } = attrs.member;
      return (0, _virtualDom.h)("a", {
        attributes: {
          class: "group-timezones-member-avatar",
          "data-user-card": username
        }
      }, (0, _post.avatarImg)("small", {
        template: avatar_template,
        username: name || (0, _utilities.formatUsername)(username)
      }));
    }
  });
});