define("discourse/plugins/discourse-calendar/discourse/adapters/discourse-post-event-invitee", ["exports", "discourse/plugins/discourse-calendar/discourse/adapters/discourse-post-event-nested-adapter"], function (_exports, _discoursePostEventNestedAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discoursePostEventNestedAdapter.default.extend({
    apiNameFor() {
      return "invitee";
    }
  });
});