define("discourse/plugins/discourse-calendar/discourse/widgets/going-button", ["exports", "discourse/widgets/widget"], function (_exports, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("going-button", {
    tagName: "button.going-button.btn.btn-default",
    click() {
      this.sendWidgetAction("changeWatchingInviteeStatus", "going");
    },
    template: function (attrs, state) {
      var __h1 = __widget_helpers.iconNode;
      var _r = [];
      _r.push("\n    ");
      _r.push(__h1("check"));
      _r.push("\n    ");
      var _a0 = [];
      _a0.push("\n      ");
      _a0.push(I18n.t("discourse_calendar.discourse_post_event.models.invitee.status.going"));
      _a0.push("\n    ");
      _r.push(virtualDom.h('span', {
        "className": "label",
        "attributes": {}
      }, _a0));
      _r.push("\n  ");
      return _r;
    }
  });
});