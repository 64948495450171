define("discourse/plugins/discourse-calendar/discourse/templates/discourse-post-event-upcoming-events", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{outlet}}
  */
  {
    "id": "+Rik5RgH",
    "block": "[[[46,[28,[37,1],null,null],null,null,null]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/templates/discourse-post-event-upcoming-events.hbs",
    "isStrictMode": false
  });
});