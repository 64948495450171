define("discourse/plugins/discourse-calendar/discourse/lib/guess-best-date-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = guessDateFormat;
  _exports.isNotFullDayEvent = isNotFullDayEvent;
  function isNotFullDayEvent(startsAt, endsAt) {
    return startsAt.hours() > 0 || startsAt.minutes() > 0 || endsAt && (moment(endsAt).hours() > 0 || moment(endsAt).minutes() > 0);
  }
  function guessDateFormat(startsAt, endsAt) {
    let format;
    if (!isNotFullDayEvent(startsAt, endsAt)) {
      format = "LL";
    } else {
      format = "LLL";
    }
    return format;
  }
});