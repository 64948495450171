define("discourse/plugins/discourse-calendar/discourse/widgets/discourse-group-timezone", ["exports", "discourse/widgets/widget"], function (_exports, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-group-timezone", {
    tagName: "div.group-timezone",
    buildClasses(attrs) {
      const classes = [];
      if (attrs.groupedTimezone.closeToWorkingHours) {
        classes.push("close-to-working-hours");
      }
      if (attrs.groupedTimezone.inWorkingHours) {
        classes.push("in-working-hours");
      }
      return classes.join(" ");
    },
    transform(attrs) {
      return {
        formatedTime: attrs.groupedTimezone.nowWithOffset.format("LT")
      };
    },
    template: function (attrs, state) {
      var __h1 = __widget_helpers.rawHtml;
      var _r = [];
      _r.push("\n    ");
      var _a0 = [];
      _a0.push("\n      ");
      var _a1 = [];
      _a1.push("\n        ");
      _a1.push(this.transformed.formatedTime);
      _a1.push("\n      ");
      _a0.push(virtualDom.h('span', {
        "className": "time",
        "attributes": {}
      }, _a1));
      _a0.push("\n      ");
      var _a2 = [];
      _a2.push("\n        ");
      _a2.push(new __h1({
        html: '<span>' + attrs.groupedTimezone.utcOffset + '</span>'
      }));
      _a2.push("\n      ");
      _a0.push(virtualDom.h('span', {
        "className": "offset",
        "attributes": {
          "title": "UTC offset"
        }
      }, _a2));
      _a0.push("\n    ");
      _r.push(virtualDom.h('div', {
        "className": "info",
        "attributes": {}
      }, _a0));
      _r.push("\n    ");
      var _a3 = [];
      _a3.push("\n");
      if (attrs.groupedTimezone.members && attrs.groupedTimezone.members.length) {
        attrs.groupedTimezone.members.forEach(member => {
          _a3.push("        ");
          _a3.push(this.attach("discourse-group-timezones-member", {
            "usersOnHoliday": attrs.usersOnHoliday,
            "member": member
          }, undefined, undefined));
          _a3.push("\n");
        });
      }
      _a3.push("    ");
      _r.push(virtualDom.h('ul', {
        "className": "group-timezones-members",
        "attributes": {}
      }, _a3));
      _r.push("\n  ");
      return _r;
    }
  });
});