define("discourse/plugins/discourse-calendar/discourse/initializers/disable-sort", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "disable-sort",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", api => {
        api.modifyClass("component:topic-list", dt7948.p({
          pluginId: "discourse-calendar",
          sortable(category, disable_resorting_on_categories_enabled) {
            let disableSort = false;
            if (disable_resorting_on_categories_enabled && category?.custom_fields) {
              disableSort = !!category.custom_fields["disable_topic_resorting"];
            }
            return !!this.changeSort && !disableSort;
          }
        }, [["method", "sortable", [(0, _decorators.default)("category", "siteSettings.disable_resorting_on_categories_enabled")]]]));
      });
    }
  };
});