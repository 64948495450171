define("discourse/plugins/discourse-calendar/discourse/widgets/discourse-post-event-status", ["exports", "discourse/widgets/widget"], function (_exports, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-post-event-status", {
    tagName: "div.event-status",
    buildKey: attrs => `discourse-post-event-status-${attrs.id}`,
    buildClasses(attrs) {
      if (attrs.watchingInvitee) {
        return `status-${attrs.watchingInvitee.status}`;
      }
    },
    template: function (attrs, state) {
      var _r = [];
      _r.push("\n");
      if (!attrs.minimal) {
        _r.push("      ");
        _r.push(this.attach("going-button", undefined, undefined, undefined));
        _r.push("\n");
      }
      _r.push("    ");
      _r.push(this.attach("interested-button", undefined, undefined, undefined));
      _r.push("\n");
      if (!attrs.minimal) {
        _r.push("      ");
        _r.push(this.attach("not-going-button", undefined, undefined, undefined));
        _r.push("\n");
      }
      _r.push("  ");
      return _r;
    }
  });
});