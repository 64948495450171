define("discourse/plugins/discourse-calendar/discourse/components/bulk-invite-sample-csv-file", ["exports", "@ember/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @label="discourse_calendar.discourse_post_event.bulk_invite_modal.download_sample_csv"
    @action={{action "downloadSampleCsv"}}
  />
  */
  {
    "id": "/3bi2HOZ",
    "block": "[[[8,[39,0],null,[[\"@label\",\"@action\"],[\"discourse_calendar.discourse_post_event.bulk_invite_modal.download_sample_csv\",[28,[37,1],[[30,0],\"downloadSampleCsv\"],null]]],null]],[],false,[\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/components/bulk-invite-sample-csv-file.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    downloadSampleCsv() {
      const sampleData = [["my_awesome_group", "going"], ["lucy", "interested"], ["mark", "not_going"], ["sam", "unknown"]];
      let csv = "";
      sampleData.forEach(row => {
        csv += row.join(",");
        csv += "\n";
      });
      const btn = document.createElement("a");
      btn.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      btn.target = "_blank";
      btn.rel = "noopener noreferrer";
      btn.download = "bulk-invite-sample.csv";
      btn.click();
    }
  }, [["method", "downloadSampleCsv", [_object.action]]])));
});