define("discourse/plugins/discourse-calendar/discourse/models/discourse-post-event-reminder", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rest.default.extend({
    init() {
      this._super(...arguments);
      this.__type = "discourse-post-event-reminder";
    }
  });
});