define("discourse/plugins/discourse-calendar/discourse/initializers/event-relative-date", ["exports", "@ember/runloop", "discourse-common/config/environment", "discourse-common/lib/later", "discourse/plugins/discourse-calendar/discourse/lib/event-relative-date"], function (_exports, _runloop, _environment, _later, _eventRelativeDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function computeRelativeEventDates() {
    document.querySelectorAll(".event-relative-date.topic-list").forEach(dateContainer => (0, _eventRelativeDate.default)(dateContainer));
  }
  var _default = _exports.default = {
    name: "event-future-date",
    initialize() {
      computeRelativeEventDates();
      if (!(0, _environment.isTesting)()) {
        this._tick();
      }
    },
    teardown() {
      if (this._interval) {
        (0, _runloop.cancel)(this._interval);
        this._interval = null;
      }
    },
    _tick() {
      this._interval && (0, _runloop.cancel)(this._interval);
      this._interval = (0, _later.default)(() => {
        computeRelativeEventDates();
        this._tick();
      }, 60 * 1000);
    }
  };
});