define("discourse/plugins/discourse-calendar/discourse/discourse-event-upcoming-events-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("discourse-post-event-upcoming-events", {
      path: "/upcoming-events"
    }, function () {
      this.route("index", {
        path: "/"
      });
    });
  }
});